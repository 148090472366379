// 通用的 PullToRefresh 组件封装
import PullToRefresh from '@/components/base/basePullToRefresh';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import { useModel } from '@umijs/max';
import { useDebounceFn, useInViewport, useThrottle } from 'ahooks';
import React, { useEffect, useRef, useState } from 'react';

interface BasePullListProps {
  fetchFunction: any; // 获取列表的 API 方法
  params: any; // usePageList 的参数
  renderItem: (item: any, index: number) => React.ReactNode; // 列表项的渲染方法
  className?: string; // 可选，额外的样式
  setParams?: any; // 可选，额外的样式
  loadingDisplay?: boolean; // 列表是否需要 loading 图标加载状态
  setLoadingDisplay?: (val: boolean) => void; // 修改外部加载状态值 配合loadingDisplay 做自定义加载
  customEmpty?: React.ReactNode;
  ready?: boolean;
  manual?: boolean;
}

const BasePullList: React.FC<BasePullListProps> = ({
                                                     fetchFunction,
                                                     params,
                                                     renderItem,
                                                     className = '',
                                                     setParams,
                                                     loadingDisplay = true,
                                                     setLoadingDisplay,
                                                     customEmpty,
                                                     ready = true,
                                                     manual = false,
                                                   }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [inViewport] = useInViewport(ref);

  // 缓存最终传给 usePaginationPage 的参数
  const [cachedParams, setCachedParams] = useState(params);

  const { run: getReloadList } = useDebounceFn(
    (params?: any) => {
      setCachedParams(params);
    },
    {
      wait: 100,
    },
  );

  // 当 throttledParams 变化并且元素可见时，更新 cachedParams
  useEffect(() => {
    if (inViewport) {
      getReloadList(params);
    }
  }, [params, inViewport]);

  // 调用 usePageList 获取分页数据
  const {
    list,
    loading,
    loadMore,
    error,
    total,
    subscriptionList = [] as any,
    ...reset
  } = usePaginationPage({
    fn: fetchFunction,
    params: cachedParams,
    setParams: setParams,
    ready: ready,
    manual
    // 如果是简约模式外部传参就不必请求
  });

  const { sendMsg, isSub } = useModel('socket');

  useEffect(() => {
    //  阶梯 订阅
    if (subscriptionList?.length !== 0) {
      // 如果有 symbol 才会去 订阅列表
      if (subscriptionList?.[0]?.symbol) {
        sendMsg(
          `{"type":"SUB","data": ${JSON.stringify(
            subscriptionList?.map((item: any) => item.symbol),
          )}}`,
        );
      }
    }
  }, [subscriptionList, isSub]);

  // 修改加载状态值 用于外部做加载
  useEffect(() => {
    if (!loadingDisplay && setLoadingDisplay) {
      setLoadingDisplay?.(loading);
    }
  }, [loading]);

  return (
    <div className={`${className}`} ref={ref}>
      <PullToRefresh
        loadMore={loadMore!}
        total={total!}
        list={list!}
        loading={loading!}
        error={!!error}
        loadingDisplay={loadingDisplay}
        customEmpty={customEmpty}
        {...reset}
      >
        {list.map((item: any, index: number) => renderItem(item, index))}
      </PullToRefresh>
    </div>
  );
};

export default BasePullList;
