import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import BasePullList from '@/components/base/basePullList/index';
import BaseTopNav from '@/components/base/baseTopNav';
import { getInviteListApi } from '@/services/api/requestApi';
import RenderUtil from '@/utils/RenderUtil';
import { cn, Divider } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const { exchangeConverFunds } = useModel('exchange');
  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });

  // const { list, loading, loadMore, error, total, ...resetContractPageProps } =
  //   usePaginationPage({
  //     fn: getInviteListApi,
  //     params: pageParams,
  //     setParams: setPageParams,
  //   });
  // 用于做外部加载
  const [loadingDisplay, setLoadingDisplay] = useState(false);
  return (
    <div className="w-full">
      <BaseTopNav
        title={t('邀请详情')}
        rightNode={
          <>
            {loadingDisplay && (
              <div
                className={cn('flex items-center justify-center w-fit h-full mr-4')}
              >
                <SvgSpinnersBarsRotateFade />
              </div>
            )}
          </>
        }
      />
      <div className="w-full text-xs text-auxiliaryTextColor flex items-center grid-cols-3  text-[14px] leading-[22px] font-medium">
        <span className="w-full text-center py-[9px]">{t('好友UID')}</span>
        <span className="w-full text-center py-[9px]">
          {t('金额') + '(USDT)'}
        </span>
        <span className="w-full text-center py-[9px]">{t('时间')}</span>
      </div>
      <Divider className="!bg-backgroundAuxiliaryColor" />
      <BasePullList
        fetchFunction={getInviteListApi} // 请求数据函数
        params={pageParams} // 请求参数
        setParams={setPageParams}
        loadingDisplay={false}
        setLoadingDisplay={setLoadingDisplay}
        renderItem={(item: any, index: number) => {
          return (
            <div
              key={index}
              className="py-[13px] w-full flex items-center grid-cols-3 text-[14px] leading-[22px] font-medium"
            >
              <span className="w-full text-center text-backContrastColor">
                {item?.inviteUid}
              </span>
              <span className="w-full text-center ">
                {exchangeConverFunds({
                  price: item?.rebateAmount,
                  defaultVal: '--', // loading 的时候显示的
                  len: 2,
                  padZeroes: true,
                  isShowCoinName: true,
                })}
              </span>
              <span className="w-full text-center text-backContrastColor">
                {RenderUtil.formatDate(item?.createTime, 'YYYY-MM-DD HH:mm:ss')}
              </span>
            </div>
          );
        }}
      />

      {/* <PullToRefresh
        loadMore={loadMore}
        total={total}
        list={list}
        error={error}
        loading={loading}
        {...resetContractPageProps}
      >
        <div className=" flex flex-col gap-[32px]">
          //渲染列表
          <div className="w-full gap">
            {list?.map?.((item: any, index: any) => {
              return (
                <div
                  key={index}
                  className="py-[13px] w-full flex items-center grid-cols-3 text-[14px] leading-[22px] font-medium"
                >
                  <span className="w-full text-center text-backContrastColor">
                    {item?.inviteUid}
                  </span>
                  <span className="w-full text-center ">
                    {exchangeConverFunds({
                      price: item?.rebateAmount,
                      defaultVal: '--', // loading 的时候显示的
                      len:2,
                      padZeroes:true,
                      isShowCoinName: true,
                    })}
                  </span>
                  <span className="w-full text-center text-backContrastColor">
                    {RenderUtil.formatDate(item?.createTime, 'YYYY-MM-DD HH:mm:ss')}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </PullToRefresh> */}
    </div>
  );
};
